export default function useMarkers() {
  const mapMarkers = [
    {
      name: "bars",
      icon: require("@/assets/markers/bars.png"),
    },
    {
      name: "business",
      icon: require("@/assets/markers/business.png"),
    },
    {
      name: "concerts",
      icon: require("@/assets/markers/concerts.png"),
    },
    {
      name: "default",
      icon: require("@/assets/markers/default.png"),
    },
    {
      name: "education",
      icon: require("@/assets/markers/education.png"),
    },
    {
      name: "employment",
      icon: require("@/assets/markers/employment.png"),
    },
    {
      name: "exhibitions",
      icon: require("@/assets/markers/exhibitions.png"),
    },
    {
      name: "financial-services",
      icon: require("@/assets/markers/financial-services.png"),
    },
    {
      name: "government",
      icon: require("@/assets/markers/government.png"),
    },
    {
      name: "hotels",
      icon: require("@/assets/markers/hotels.png"),
    },
    {
      name: "industries",
      icon: require("@/assets/markers/industries.png"),
    },
    {
      name: "internet",
      icon: require("@/assets/markers/internet.png"),
    },
    {
      name: "karaoke",
      icon: require("@/assets/markers/karaoke.png"),
    },
    {
      name: "lounges",
      icon: require("@/assets/markers/lounges.png"),
    },
    {
      name: "medical",
      icon: require("@/assets/markers/medical.png"),
    },
    {
      name: "meetups",
      icon: require("@/assets/markers/meetups.png"),
    },
    {
      name: "museums",
      icon: require("@/assets/markers/museums.png"),
    },
    {
      name: "nightlife",
      icon: require("@/assets/markers/nightlife.png"),
    },
    {
      name: "photography",
      icon: require("@/assets/markers/photography.png"),
    },
    {
      name: "places",
      icon: require("@/assets/markers/places.png"),
    },
    {
      name: "professional",
      icon: require("@/assets/markers/professional.png"),
    },
    {
      name: "real-estate",
      icon: require("@/assets/markers/real-estate.png"),
    },
    {
      name: "residential-places",
      icon: require("@/assets/markers/residential-places.png"),
    },
    {
      name: "restaurants",
      icon: require("@/assets/markers/restaurants.png"),
    },
    {
      name: "schools",
      icon: require("@/assets/markers/schools.png"),
    },
    {
      name: "science",
      icon: require("@/assets/markers/science.png"),
    },
    {
      name: "transport",
      icon: require("@/assets/markers/transport.png"),
    },
  ];

  const getMarker = (value: string) => {
    switch (value) {
      case "bars":
        return require("@/assets/markers/bars.png");
      case "business":
        return require("@/assets/markers/business.png");
      case "concerts":
        return require("@/assets/markers/concerts.png");
      case "default":
        return require("@/assets/markers/default.png");
      case "education":
        return require("@/assets/markers/education.png");
      case "employment":
        return require("@/assets/markers/employment.png");
      case "exhibitions":
        return require("@/assets/markers/exhibitions.png");
      case "financial-services":
        return require("@/assets/markers/financial-services.png");
      case "government":
        return require("@/assets/markers/government.png");
      case "hotels":
        return require("@/assets/markers/hotels.png");
      case "industries":
        return require("@/assets/markers/industries.png");
      case "internet":
        return require("@/assets/markers/internet.png");
      case "karaoke":
        return require("@/assets/markers/karaoke.png");
      case "lounges":
        return require("@/assets/markers/lounges.png");
      case "medical":
        return require("@/assets/markers/medical.png");
      case "meetups":
        return require("@/assets/markers/meetups.png");
      case "museums":
        return require("@/assets/markers/museums.png");
      case "nightlife":
        return require("@/assets/markers/nightlife.png");
      case "photography":
        return require("@/assets/markers/photography.png");
      case "places":
        return require("@/assets/markers/places.png");
      case "professional":
        return require("@/assets/markers/professional.png");
      case "real-estate":
        return require("@/assets/markers/real-estate.png");
      case "residential-places":
        return require("@/assets/markers/residential-places.png");
      case "restaurants":
        return require("@/assets/markers/restaurants.png");
      case "schools":
        return require("@/assets/markers/schools.png");
      case "science":
        return require("@/assets/markers/science.png");
      case "transport":
        return require("@/assets/markers/transport.png");
    }
  };

  return {
    mapMarkers,
    getMarker,
  };
}
